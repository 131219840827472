import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import type { GlobalVisualisationFilterEntityRecord } from 'entities/filter/GlobalVisualisationFilter';

export function equipmentIsFiltered(
  filterRecord: GlobalVisualisationFilterEntityRecord,
  equipment: EquipmentEntityRecord,
): boolean {
  const equipmentsFiltered = filterRecord
    ?.get('equipment_l1')
    ?.merge(filterRecord.get('equipment_l2'));
  return equipmentsFiltered?.includes(equipment);
}
