import { type List } from 'immutable';

import { type ExtendedApplicableRuleEntityRecord } from 'components/ControlGrid/components/ControlPreview';
import { RequirementCell } from 'components/ControlGrid/components/MandatoryAssessTable/RequirementCell';
import { type EquipmentEntityRecord } from 'entities/api/Equipment';
import { type UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

export type RequirementCellsProps = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  equipment: EquipmentEntityRecord;
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
  isFiltered: boolean;
};

export const EquipmentRequirementCells = ({
  operatingContexts,
  isFiltered,
  ...props
}: RequirementCellsProps) => {
  return (
    <>
      {operatingContexts.map((opCtx) => (
        <RequirementCell
          key={opCtx.get('uuid')}
          operatingContext={opCtx}
          {...props}
          equipmentIsFiltered={isFiltered}
        />
      ))}
    </>
  );
};
