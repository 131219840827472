import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import { type List } from 'immutable';

import { type ExtendedApplicableRuleEntityRecord } from 'components/ControlGrid/components/ControlPreview';
import { L1EquipmentRow } from 'components/ControlGrid/components/MandatoryAssessTable/L1EquipmentRow';
import { tableSx } from 'components/ControlGrid/components/MandatoryAssessTable.styles';
import { type RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { type UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

import { MandatoryAssessTableHeaderRow } from './MandatoryAssessTableHeaderRow';

type MandatoryAssessTableProps = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  scenarios: List<RulePredicateEntityRecord>;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  equipmentLevel1: UseControlVisualisationControlsReturn['equipmentLevel1'];
  equipmentLevel2: UseControlVisualisationControlsReturn['equipmentLevel2'];
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
};

export const MandatoryAssessTable = ({
  applicableRules,
  scenarios,
  filterRecord,
  equipmentLevel1,
  equipmentLevel2,
  operatingContexts,
}: MandatoryAssessTableProps) => {
  return (
    <TableContainer>
      <Table size="sm" sx={tableSx}>
        <MandatoryAssessTableHeaderRow
          operatingContexts={operatingContexts}
          filterRecord={filterRecord}
        />
        <Tbody>
          {equipmentLevel1.map((l1Equipment, i) => {
            return (
              <L1EquipmentRow
                key={l1Equipment.get('uuid')}
                equipmentLevel1={l1Equipment}
                applicableRules={applicableRules}
                scenarios={scenarios}
                filterRecord={filterRecord}
                allEquipmentLevel2={equipmentLevel2}
                operatingContexts={operatingContexts}
                showScenario={i === 0}
              />
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
