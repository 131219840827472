import { Td, Text } from '@chakra-ui/react';
import { type List } from 'immutable';
import { useMemo } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { type ExtendedApplicableRuleEntityRecord } from 'components/ControlGrid/components/ControlPreview';
import { type EquipmentEntityRecord } from 'entities/api/Equipment';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import { type UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

type Props = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  equipment: EquipmentEntityRecord;
  operatingContext: OperatingContextEntityRecord;
  equipmentIsFiltered: boolean;
};

const mandatory = defineMessage({
  id: 'MandatoryAssessTable.mandatory',
  defaultMessage: 'Mandatory',
});
const assess = defineMessage({
  id: 'MandatoryAssessTable.assess',
  defaultMessage: 'Assess',
});

export const RequirementCell = ({
  applicableRules,
  filterRecord,
  equipment,
  operatingContext,
  equipmentIsFiltered,
}: Props) => {
  const { formatMessage } = useIntl();

  const rulesMatchingContextAndEquipment = useMemo(
    () =>
      applicableRules.filter((rule) => {
        const applicableRulesEquipmentUuids = rule
          .get('predicatesByModelName')
          ?.get('equipment')
          ?.map((predicate) => predicate.get('object_id'))
          .toSet();

        const opCtxUuids = rule
          .get('predicatesByModelName')
          ?.get('operatingcontext')
          ?.map((predicate) => predicate.get('object_id'));

        return (
          applicableRulesEquipmentUuids?.includes(equipment.get('uuid')) &&
          opCtxUuids?.includes(operatingContext.get('uuid'))
        );
      }),
    [applicableRules, equipment, operatingContext],
  );

  const operatingContextsFiltered = filterRecord?.get('operating_context');

  return (
    <Td
      className={[
        equipmentIsFiltered ? 'isFilteredRow' : '',
        operatingContextsFiltered.includes(operatingContext)
          ? 'isFilteredColumn'
          : '',
      ].join(' ')}
    >
      {rulesMatchingContextAndEquipment.count() ? (
        <Text as="span">
          {`${
            rulesMatchingContextAndEquipment.some((rule) =>
              rule.get('is_mandatory'),
            )
              ? formatMessage(mandatory)
              : formatMessage(assess)
          }${rulesMatchingContextAndEquipment.count() > 1 ? ' *' : ''}`}
        </Text>
      ) : (
        '-'
      )}
    </Td>
  );
};
