import { ListItem, Td, UnorderedList } from '@chakra-ui/react';
import { type List } from 'immutable';

import type { RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { useLocale } from 'locales/useLocale';

export type ScenarioCellProps = {
  scenarios: List<RulePredicateEntityRecord>;
};

export const ScenarioCell = ({ scenarios }: ScenarioCellProps) => {
  const { toString } = useLocale();

  const scenarioTitles = scenarios
    .map((el) => toString(el))
    .toSet()
    .toList();

  return (
    <Td rowSpan={0} className="scenarios">
      <UnorderedList>
        {scenarioTitles.map((title: string, j: number) => (
          <ListItem key={j}>{title}</ListItem>
        ))}
      </UnorderedList>
    </Td>
  );
};
