import { Th } from '@chakra-ui/react';
import { type List } from 'immutable';

import { type ExtendedApplicableRuleEntityRecord } from 'components/ControlGrid/components/ControlPreview';
import { type EquipmentEntityRecord } from 'entities/api/Equipment';
import { useLocale } from 'locales/useLocale';
import { type UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

import { equipmentIsFiltered } from './equipmentIsFiltered';
import { EquipmentRequirementCells } from './RequirementCells';

export type L2EquipmentRowProps = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  equipmentLevel2: EquipmentEntityRecord;
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
  isParentFiltered: boolean;
  isAnySiblingFiltered: boolean;
};

export const L2EquipmentRow = ({
  equipmentLevel2,
  filterRecord,
  isParentFiltered,
  isAnySiblingFiltered,
  ...props
}: L2EquipmentRowProps) => {
  const { toString } = useLocale();

  const isFiltered = equipmentIsFiltered(filterRecord, equipmentLevel2);

  return (
    <>
      <Th
        className={[
          'equipment-l2',
          isFiltered || (isParentFiltered && !isAnySiblingFiltered)
            ? 'isFilteredRow'
            : '',
        ].join(' ')}
      >
        {toString(equipmentLevel2)}
      </Th>
      <EquipmentRequirementCells
        equipment={equipmentLevel2}
        filterRecord={filterRecord}
        isFiltered={isFiltered || (isParentFiltered && !isAnySiblingFiltered)}
        {...props}
      />
    </>
  );
};
