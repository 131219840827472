import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { trackLogin } from '@burnsred/ui-chakra';
import { axiosInstance } from 'api/axiosInstance';
import PersonEntity, {
  type PersonEntityRecord,
  useCurrentUserPreferences,
} from 'entities/api/Person/Person';
import UserPreferencesEntity from 'entities/api/Person/UserPreferences';
import { useLocale } from 'locales/useLocale';
import { GLOBAL_FRAMEWORK_TABS } from 'screens/global-frameworks/GlobalFramework/GlobalFramework';
import { settings } from 'settings';

const INTERVAL = settings.SESSION_CHECK_INTERVAL || 60000;

export function checkForRedirect(
  user: PersonEntityRecord,
  pathname: string,
  callback: () => boolean | null | void,
) {
  if (user && PersonEntity.isPublicUser(user)) {
    if (!pathname.includes(GLOBAL_FRAMEWORK_TABS.base)) {
      // Redirect to global frameworks tab
      // nicer if we can do this in routes.ts somehow.
      return callback();
    }
  }
  return false;
}

export function useRequireAuthInterface() {
  const dispatch = useDispatch();
  const auth = useQuery<PersonEntityRecord>({
    action: PersonEntity.duck.actions.whoAmI(),
  });
  // User preferences.
  const preferences = useCurrentUserPreferences();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const login = useCallback(() => {
    dispatch(PersonEntity.duck.actions.whoAmI()).promise.then(() => {
      onClose();
    });
  }, [dispatch, onClose]);

  // Weirdness where useQuery put value returned from whoami into ValueInitial
  const { valueInitial: user, processing: isUserLoading } = auth;

  useEffect(() => {
    if (auth?.valueInitial) {
      // corresponds to /admin/bhp_people/person/:uuid
      // note: due to application design, we don't track logout
      trackLogin((auth.valueInitial as PersonEntityRecord)?.get('uuid'));
      dispatch(
        UserPreferencesEntity.duck.actions.get({
          id: auth.valueInitial.get('user'),
        }),
      );
    }
  }, [auth?.valueInitial, dispatch]);

  useEffect(() => {
    if (!isOpen) {
      const intervalId = setInterval(() => {
        axiosInstance.get('/signin/v1/session-status/').then((response) => {
          const remaining = moment.duration(response?.data?.remaining);
          if (
            remaining.isValid() &&
            remaining.as('seconds') < INTERVAL / 1000 + 10
          ) {
            onOpen();
            clearInterval(intervalId);
          }
        });
      }, INTERVAL);

      return () => {
        // Would be nice to also clear the person in redux here, but no
        // implemented clear method, needs to be added to library
        clearInterval(intervalId);
      };
    }
  }, [onOpen, isOpen]);

  // Below block is structured to prevent a redirect immediately during rendering,
  // The redirect is moved to a post redner effect hook,
  // the if statement prevents the page from loading but allows the render to complete
  // If we redirect during rendering we get react set state during rendering errors.
  useEffect(() => {
    checkForRedirect(user, pathname, () =>
      navigate(GLOBAL_FRAMEWORK_TABS.base),
    );
  }, [user, pathname, navigate]);

  const { locale } = useLocale();
  const localeSynced = preferences && preferences?.get('locale') === locale;
  return {
    user,
    pathname,
    isUserLoading,
    isOpen,
    login,
    preferences,
    localeSynced,
  };
}
