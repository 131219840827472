import { type List, type Map } from 'immutable';
import { type MouseEventHandler, useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import type { MPTTEntityRecord } from 'entities/api/MPTT';

import { type UseTreePickerReturn, useTreePicker } from '../TreePicker.hooks';

const HACK_RETURN_EMPTY_UUID = '00000000-0000-0000-0000-000000000000';

export type TreePickerListProps<T extends MPTTEntityRecord> = Pick<
  UseTreePickerReturn<T>,
  'Entity' | 'baseParams'
> & {
  level: number;
  parents: List<string>;
  items: List<T>;
};

/**
 * Handles fetching the items for this level of list
 *
 * (re-exports all of useTreePicker's returns)
 */
export const useTreePickerList = <
  T extends Map<string, unknown> = Map<string, unknown>,
>({
  level,
  parent,
}: {
  level: number;
  parent: string;
}) => {
  const treePickerContext = useTreePicker();
  const { Entity, baseParams, parents, onChangeParents, onChangeList } =
    treePickerContext;

  const params = useMemo(
    () =>
      baseParams.merge({
        parent: parent ?? HACK_RETURN_EMPTY_UUID,
        // _debug: `level-${level}`,
      }) as Map<string, string>,
    [baseParams, parent],
  );

  // repeat queries with the same params are cached
  const { value: children } = useQuery<List<T>>({
    action: Entity.duck.actions.get({ params }),
  });

  /** FIXME doesn't help Node */
  const getIsExpanded = useCallback(
    (item: T) => parents.includes(item?.get('uuid') as string),
    [parents],
  );

  useEffect(() => {
    if (children?.size) onChangeList({ parent, list: children });

    // don't re-run for onChangeList or we get a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, children]);

  const handleToggleExpanded: MouseEventHandler = (event) => {
    // REVIEW does this play into the AccordionPicker no-open glitch?
    const parent =
      (event?.currentTarget as HTMLDivElement)?.dataset?.uuid ?? '';

    // set the clicked item as the parent _of the child list_
    onChangeParents({ childLevel: level + 1, parent });
  };

  return {
    getIsExpanded,
    handleToggleExpanded,

    // re-export everything from useTreePicker
    ...treePickerContext,
  };
};
