import type { SystemStyleObject } from '@chakra-ui/react';

export const haveAssessSx: SystemStyleObject = {
  height: '40px',
  alignItems: 'center',
};

export const buttonSx: SystemStyleObject = {
  display: 'flex',
  alignItems: 'flex-end',
  marginLeft: 'auto',
};

export const simpleGridSx: SystemStyleObject = {
  alignItems: 'end',
};

export const FilterCount = ({ count }: { count?: number }) =>
  count ? <span> ({count})</span> : null;
