import { Th, Tr } from '@chakra-ui/react';
import { type List } from 'immutable';

import { type ExtendedApplicableRuleEntityRecord } from 'components/ControlGrid/components/ControlPreview';
import { equipmentIsFiltered } from 'components/ControlGrid/components/MandatoryAssessTable/equipmentIsFiltered';
import { L2EquipmentRow } from 'components/ControlGrid/components/MandatoryAssessTable/L2EquipmentRow';
import { EquipmentRequirementCells } from 'components/ControlGrid/components/MandatoryAssessTable/RequirementCells';
import { ScenarioCell } from 'components/ControlGrid/components/MandatoryAssessTable/ScenarioCell';
import { type EquipmentEntityRecord } from 'entities/api/Equipment';
import { type RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { useLocale } from 'locales/useLocale';
import { type UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

export type L1EquipmentRowProps = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  scenarios: List<RulePredicateEntityRecord>;
  equipmentLevel1: EquipmentEntityRecord;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  allEquipmentLevel2: UseControlVisualisationControlsReturn['equipmentLevel2'];
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
  showScenario: boolean;
};

export const L1EquipmentRow = ({
  applicableRules,
  scenarios,
  filterRecord,
  equipmentLevel1,
  allEquipmentLevel2,
  operatingContexts,
  showScenario,
}: L1EquipmentRowProps) => {
  const { toString } = useLocale();
  const equipmentChildren = allEquipmentLevel2.filter(
    (l2Equipment) => l2Equipment.get('parent') == equipmentLevel1.get('uuid'),
  );
  const isFiltered = equipmentIsFiltered(filterRecord, equipmentLevel1);
  const filteredL2Equipment = filterRecord.get('equipment_l2') || [];
  const isChildFiltered = equipmentChildren.some((child) => {
    return equipmentIsFiltered(filterRecord, child);
  });

  const rowHeaderClassname =
    isFiltered || isChildFiltered ? 'isFilteredRow' : '';

  if (equipmentChildren.count() === 0) {
    return (
      <Tr>
        <Th className={rowHeaderClassname}>{toString(equipmentLevel1)}</Th>
        <Th
          className={['equipment-l2', isFiltered ? 'isFilteredRow' : ''].join(
            ' ',
          )}
        >
          -
        </Th>
        <EquipmentRequirementCells
          equipment={equipmentLevel1}
          applicableRules={applicableRules}
          filterRecord={filterRecord}
          operatingContexts={operatingContexts}
          isFiltered={isFiltered}
        />

        {showScenario && <ScenarioCell scenarios={scenarios} />}
      </Tr>
    );
  }

  return (
    <>
      {equipmentChildren.map((equipmentChild, index) => {
        const siblings = equipmentChildren.filter(
          (child) => child.get('uuid') !== equipmentChild.get('uuid'),
        );

        const isAnySiblingFiltered = siblings.some((sibling) => {
          return filteredL2Equipment.includes(sibling);
        });

        return (
          <Tr key={equipmentChild.get('uuid')}>
            {index === 0 && (
              <Th
                rowSpan={equipmentChildren.count()}
                className={rowHeaderClassname}
              >
                {toString(equipmentLevel1)}
              </Th>
            )}
            <L2EquipmentRow
              equipmentLevel2={equipmentChild}
              applicableRules={applicableRules}
              filterRecord={filterRecord}
              operatingContexts={operatingContexts}
              isParentFiltered={isFiltered}
              isAnySiblingFiltered={isAnySiblingFiltered}
            />
            {showScenario && index === 0 && (
              <ScenarioCell scenarios={scenarios} />
            )}
          </Tr>
        );
      })}
    </>
  );
};
