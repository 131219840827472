import {
  Box,
  Button,
  CloseButton,
  GridItem,
  HStack,
  Heading,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { List, type OrderedMap, fromJS } from 'immutable';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQuery } from '@burnsred/entity-duck-query';
import { Loading, RouterLink } from '@burnsred/ui-chakra';
import { VStack } from 'components/atoms';
import {
  ContentBlock,
  buttonSx,
  closeButtonSx,
  col3Sx,
  gridItemSx,
  listStyles,
} from 'components/ControlGrid/components/ControlPreview.styles';
import { MandatoryAssessTable } from 'components/ControlGrid/components/MandatoryAssessTable';
import { headingSx } from 'components/PerformanceCard/PerformanceCard.style';
import { RiskIcon } from 'components/RiskIcon';
import { Risk } from 'entities';
import ApplicableRuleEntity, {
  type ApplicableRuleEntityFields,
  type ApplicableRuleEntityRecord,
} from 'entities/api/ApplicableRule';
import type { DamageEnergyMechanismEntityRecord } from 'entities/api/DamageEnergyMechanism';
import DamageEnergyMechanismEntity from 'entities/api/DamageEnergyMechanism';
import type {
  PredicateKey,
  RulePredicateEntityRecord,
} from 'entities/api/RulePredicate';
import { useLocale } from 'locales/useLocale';
import type {
  CommonControlEntityRecord,
  CommonFrameworkEntityRecord,
} from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation';
import type { UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';
import type { EntityRecord } from 'types';
import { createLogger } from 'util/createLogger';

const log = createLogger('ControlPreview');

type PredicatesByModelNameFields = {
  predicatesByModelName: OrderedMap<
    PredicateKey,
    List<RulePredicateEntityRecord>
  >;
};
export type ExtendedApplicableRuleEntityRecord = EntityRecord<
  ApplicableRuleEntityFields & PredicatesByModelNameFields
>;

type ControlPreviewProps = {
  framework: CommonFrameworkEntityRecord;
  control: CommonControlEntityRecord;
  setExpandedControl: React.Dispatch<React.SetStateAction<string | null>>;
  controlHref: string;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  equipmentLevel1: UseControlVisualisationControlsReturn['equipmentLevel1'];
  equipmentLevel2: UseControlVisualisationControlsReturn['equipmentLevel2'];
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
};

export const ControlPreview = ({
  framework,
  control,
  setExpandedControl,
  controlHref,
  filterRecord,
  equipmentLevel1,
  equipmentLevel2,
  operatingContexts,
}: ControlPreviewProps) => {
  const { toString } = useLocale();

  const params = useMemo(
    () =>
      fromJS({
        control: control?.get('uuid'),
      }),
    [control],
  );

  // FIXME BC-42 error handling
  const {
    value: _applicableRules,
    processing: isLoading,
    errors: _,
  } = useQuery<List<ApplicableRuleEntityRecord>>({
    action: ApplicableRuleEntity.duck.actions.get({ params }),
  });

  const iconPath = Risk.getRiskIconPath(framework?.get('risk'));

  const inScope = toString(control, 'in_scope');
  const outOfScope = toString(control, 'out_of_scope');

  /** extend applicableRules with predicatesByModelName */
  const applicableRules = useMemo(
    () =>
      (_applicableRules ?? List([]))?.map((rule) =>
        rule.set(
          'predicatesByModelName',
          rule
            .get('predicates')
            .groupBy((predicate) => predicate.get('model_name')),
        ),
      ) as List<ExtendedApplicableRuleEntityRecord>,
    [_applicableRules],
  );

  const scenarios = applicableRules
    .map((rule) => rule.get('predicatesByModelName'))
    .flatMap<RulePredicateEntityRecord>(
      (group) => group?.get('scenario') ?? List(),
    );
  const scenarioUuids = scenarios
    .map((el) => el.get('object_id'))
    .toSet() // de-duplicate
    .toList();

  const demParams = useMemo(
    () => fromJS({ damage_energy__risk: framework.get('risk').get('uuid') }),
    [framework],
  );
  const { value: _damageEnergyMechanisms } = useQuery<
    List<DamageEnergyMechanismEntityRecord>
  >({
    action: DamageEnergyMechanismEntity.duck.actions.get({
      params: demParams,
    }),
  });

  const damagingEnergyMechanisms = useMemo(() => {
    const demTitleStrings = _damageEnergyMechanisms
      ?.filter((dem, _i, _demList) =>
        dem
          ?.get('scenarios')
          ?.map((s) => s.get('uuid'))
          ?.some((uuid, _j, _uuidList) => scenarioUuids.includes(uuid)),
      )
      ?.map((el) => toString(el));
    return demTitleStrings?.size ? demTitleStrings : List(['-']);
  }, [_damageEnergyMechanisms, scenarioUuids, toString]);

  const maybeSupportFactors: List<string> = control
    .get('support_factors')
    .map((el) => toString(el));
  const supportFactors: List<string> = maybeSupportFactors.size
    ? maybeSupportFactors
    : List(['-']);

  log('%o', {
    framework,
    control,
    applicableRules,
    _damageEnergyMechanisms,
    damagingEnergyMechanisms,
    supportFactors,
    scenarioUuids,
  });

  return (
    <GridItem as={VStack} colStart={1} colEnd={7} sx={gridItemSx}>
      <HStack gap={3}>
        <RiskIcon path={iconPath} filter="orange" />

        <Heading as="h4" variant="primary" sx={headingSx}>
          {toString(control)}
        </Heading>

        <CloseButton
          onClick={() => setExpandedControl(null)}
          sx={closeButtonSx}
        />
      </HStack>

      <SimpleGrid columns={3} gap={6}>
        <GridItem as={VStack} gap={4}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.purpose"
                defaultMessage="Purpose"
              />
            }
          >
            <Text>{toString(control, 'purpose')}</Text>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.control-type"
                defaultMessage="Control type"
              />
            }
          >
            <Text>{toString(control.get('control_designation'))}</Text>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.damage-energy-mechanism"
                defaultMessage="Damaging energy mechanism"
              />
            }
          >
            <VStack>
              {damagingEnergyMechanisms.count() ? (
                <UnorderedList sx={listStyles}>
                  {damagingEnergyMechanisms.map((el, i) => (
                    <ListItem key={i}>{el}</ListItem>
                  ))}
                </UnorderedList>
              ) : (
                '-'
              )}
            </VStack>
          </ContentBlock>
        </GridItem>

        <GridItem as={VStack} gap={4}>
          {inScope && (
            <ContentBlock
              heading={
                <FormattedMessage
                  id="ControlPreview.heading.in_scope"
                  defaultMessage="Scope - Included"
                />
              }
            >
              <Text>{inScope}</Text>
            </ContentBlock>
          )}

          {outOfScope && (
            <ContentBlock
              heading={
                <FormattedMessage
                  id="ControlPreview.heading.out-of-scope"
                  defaultMessage="Scope - Excluded"
                />
              }
            >
              <Text>{outOfScope}</Text>
            </ContentBlock>
          )}

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.implementation-requirements"
                defaultMessage="Implementation requirements"
              />
            }
          >
            <Text whiteSpace="pre-line">
              {toString(control, 'implementation') || '-'}
            </Text>
          </ContentBlock>
        </GridItem>

        <GridItem sx={col3Sx} gap={4}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.support-factors"
                defaultMessage="Support factors"
              />
            }
          >
            <VStack>
              {supportFactors.count() ? (
                <UnorderedList sx={listStyles}>
                  {supportFactors.map((el, i) => (
                    <ListItem key={i}>{el}</ListItem>
                  ))}
                </UnorderedList>
              ) : (
                '-'
              )}
            </VStack>
          </ContentBlock>

          <Button
            as={RouterLink}
            to={controlHref}
            variant="primary"
            sx={buttonSx}
          >
            <FormattedMessage
              id="ControlPreview.cta.go-to-control"
              defaultMessage="Go to the control"
            />
          </Button>
        </GridItem>
      </SimpleGrid>

      <Box layerStyle="loadingWrapper">
        {isLoading ? (
          <Loading />
        ) : applicableRules.count() ? (
          <MandatoryAssessTable
            applicableRules={applicableRules}
            scenarios={scenarios}
            filterRecord={filterRecord}
            equipmentLevel1={equipmentLevel1}
            equipmentLevel2={equipmentLevel2}
            operatingContexts={operatingContexts}
          />
        ) : (
          // TODO test when there are no applicableRules with equipment or operatingContexts
          <FormattedMessage
            id="ControlPreview.no-applicable-rules"
            defaultMessage="No applicable rules"
          />
        )}
      </Box>
    </GridItem>
  );
};
